import React from 'react'
import ThemeProvider from 'providers/ThemeProvider'

// export const onServiceWorkerUpdateReady = () => window.location.reload(true)

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true)  window.location.reload()
}


export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
